.main-page {
  font-family: 'Michroma', sans-serif;
  padding: 30px;
  max-width: 100%;
  overflow-x: hidden;
}

.content {
  position: relative;
  width: 100vw; /* Full width of the viewport */
  height: 90vh; /* Full height of the viewport */
  text-align: center;
  overflow: hidden;
  margin: 0;
  margin-top: -4px;
  margin-left: -37px;
  padding: 0;
}

.content video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.6; /* Ensures the video covers the entire container */
  z-index: -1; /* Moves it behind the content */
}

.content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.content h1 {
  font-family: 'Michroma', sans-serif;
}

.content h1,
.content p,
.Arrow-container {
  position: relative;
  z-index: 2;
}

.chevron {
  position: absolute;
  top: 65%;
  transform: translateY(-70%);
  max-width: 80px;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
}

.chevron.left {
  left: 0;
}

.chevron.right {
  right: 0;
}

.chevron svg {
  width: 100%;
  height: 50%;
  filter: drop-shadow(0 0 10px rgb(24, 54, 226))
          drop-shadow(0 0 2px rgba(11, 14, 218, 0.986))
          drop-shadow(0 0 4px rgba(8, 16, 128, 0.952))
          drop-shadow(0 0 6px rgb(7, 5, 148));
}

.chevron-path {
  stroke: rgb(45, 23, 245);
  stroke-width: 0.5;
  fill: none;
}

.content h1 {
  margin-top: 7%;
  font-size: 6rem;
  font-weight: bolder;
  background: linear-gradient(90deg, rgb(0, 0, 0), rgb(12, 12, 12));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.content p {
  font-size: 35px;
  margin-top: -30px;
  color: rgb(0, 4, 7);
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.Arrow-container img {
  margin-top: 120px;
  cursor: pointer;
  width: 60px;
  height: 60px;
  z-index: 2;
  animation: bounce 2.5s infinite ease-in-out;
}

.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  max-width: 900px;
  height: 620px;
  flex-grow: 1;
}

.service-card-slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  border-radius: 20px;
  margin-bottom: 20px;
  padding: 20px;
  margin-left: 20%;
}

.service-card {
  flex: 0 0 80%; /* Adjust the width here */
  display: flex;
  margin-top: 50px;
  flex-direction: row;
  background: linear-gradient(145deg, #0c2d74, #053781);
  border-radius: 30px; /* Overall border-radius */
  box-shadow: 8px 8px 16px #1b1b1b, -8px -8px 16px #202020;
  overflow: hidden;
  min-height: 370px;
  padding: 30px;
  transition: transform 0.5s ease-in-out;
}

.service-card.active {
  display: flex;
}

.service-card.hidden {
  display: none;
}

.service-image {
  flex: 1;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  border-radius: 20px 0 0 20px; /* Rounded corners on the left side */
  margin-right: 10px;
}

.service-image img {
  width: 120%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px 0 0 20px; /* Match the container's border-radius */
}

.service-content {
  flex: 2;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
  border-radius: 0 30px 30px 0; /* Rounded corners on the right side */
  margin-right: 20px; /* Add margin to ensure it's not hidden */
}

.service-title {
  font-size: 32px;
  margin: 0;
  font-weight: 500;
  color: rgb(255, 255, 255);
}

.services-description {
  font-size: 1rem;
  font-family: 'Michroma', sans-serif;
  line-height: 1.5;
  color: white;
  margin: 16px 0;
  font-weight: normal;
}

.service-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details-button {
  color: white;
  border: none;
  border-radius: 2px;
  padding: 5px 15px;
  cursor: pointer;
  transition: opacity 0.3s, transform 0.3s;
  font-size: 14px;
  text-decoration: none;
}

.details-button:hover {
  opacity: 0.9;
  transform: scale(1.05);
  background-color: white;
}

.icon-container {
  background-color: #800080;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(128, 0, 128, 0.3);
}

.icon {
  color: white;
  font-size: 18px;
}

.slider-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -70px;
  margin-bottom: 20px;
}

.prev-btn, .next-btn {
  background: none;
  pointer-events: auto;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: relative; 
  z-index: 10;
}

.dots {
  display: flex;
  gap: 10px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: #004280;
}

.discover-more-btn {
  background: white;
  color: rgb(0, 0, 0);
  border: 2px solid #0a4181;
  border-radius: 12px;
  padding: 7px 10px;
  cursor: pointer;
  font-size: 16px;
  margin-top: -24px;
  margin-left: 20px;
  text-decoration: none;
}

.discover-more-btn:hover {
  background-color: #083d7a;
  color: #ffffff;
}

.service-card .boiler-inspection-card {
  margin-left: 10px;
  margin-right: 20px;
}

.why-choose-us-container {
  padding: 60px 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.why-choose-title {
  font-size: 32px;
  text-align: left;
  margin-left: 7%;
  margin-bottom: 10px;
  background: linear-gradient(90deg, rgb(22, 22, 22), rgb(150, 150, 150));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.why-choose-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  width: 100%;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.3;
}

.why-choose-grid .main-feature {
  background-color: #588ec0;
  border-radius: 20px;
  max-height: 420px;
  width: 370px;
  margin-left: 15%;
  padding: 25px;
  color: black;
  text-align: left;
  justify-content: center;
  line-height: 1.7;
}

.why-choose-grid .main-feature p {
  margin-top: 10%;
  font-family: 'Michroma', sans-serif;
  font-size: 15px;
}

.secondary-features {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 16px;
  margin-left: -5%;
}

.feature-box {
  padding: 15px;
  border-radius: 15px;
  max-height: 200px;
}

.why-choose-grid .main-feature:hover {
  background-color: #053781;
  transform: scale(1.02);
  color: #ffffff;
}

.feature-box:hover {
  background-color: #053781;
  transform: scale(1.02);
  color: #ffffff;
}

.feature-1 {
  background-color: #588ec0;
  grid-column: 1 / -1;
  margin-bottom: 10px;
  max-height: 150px;
  font-family: 'Michroma', sans-serif;
  font-size: 15px;
  line-height: 1.5;
}

.feature-2 {
  background-color: #588ec0;
  margin-right: 20px;
  max-height: 180px;
  font-family: 'Michroma', sans-serif;
  font-size: 15px;
  line-height: 1.5;
}

.feature-3 {
  background-color: #588ec0;
  margin-left: 20px;
  max-height: 180px;
  font-family: 'Michroma', sans-serif;
  font-size: 15px;
  line-height: 1.4;
}

span {
  font-weight: bold;
}

.secondary-features p {
  margin-top: -2px;
}

.testament {
  background-color: #041a4e; /* Blue-900 */
  color: white;
  padding: 3rem 1.6rem;
  text-align: center;
  margin-top: 20px;
}

.testament h2 {
  font-size: 1.875rem; /* 2xl */
  font-weight: bold;
  margin-bottom: 2rem;
  margin-top: -20px;
}

.testament-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.testament-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testament-icon {
  width: 4rem; /* 12 */
  height: 4rem;
  margin-bottom: -20px;
  margin-top: 20px;
}

.testament-number {
  font-size: 1.5rem;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.testament-text {
  font-size: 1.5rem;
  font-family: 'Michroma', sans-serif;
  margin-top: -1px; /* sm */
}

/* Media Queries */

/* Mobile (up to 767px) */
@media (max-width: 767px) {
  .main-page {
    padding: 15px;
  }

  .content {
    height: 80vh;
    margin-left: -15px;
  }

  .content h1 {
    font-size: 2.5rem;
    margin-top: 30%;
  }

  .content p {
    font-size: 14px;
    margin-top: -10px;
  }

  .Arrow-container img {
    width: 40px;
    height: 40px;
    margin-top: 60px;
  }

  .chevron {
    max-width: 50px;
  }

  .chevron svg {
    height: 40%;
  }

  .services-container {
    padding: 10px;
    max-width: 60%;
    margin-right: 20%;
    height: auto;
  }

  .service-card-slider {
    margin-left: 0;
    padding: 10px;
  }

  .service-card-slider  .service-card {
    flex-direction: column;
    flex: 0 0 100%;
    max-width: 340px;
    height: 600px;
    padding: 10px;
    margin-left: 2px;
    margin-right: 1%;
    margin-top: 20px;
  }

  .service-image {
    height: 200px;
    border-radius: 20px 20px 20px 20px;
    margin-right: 0;
  }

  .service-content {
    padding: 15px;
    margin-right: 0;
  }

  .service-title {
    font-size: 24px;
  }

  .services-description {
    font-size: 14px;
  }

  .details-button{
    max-width: 40%;
    margin-left: 25%;
  }

  .slider-navigation {
    margin-top: -40px;
    margin-bottom: 2%;
  }

  .prev-btn, .next-btn {
    font-size: 18px;
  }

  .discover-more-btn {
    font-size: 14px;
    padding: 5px 8px;
  }

  .why-choose-us-container {
    padding: 20px 10px;
  }

  .why-choose-title {
    font-size: 24px;
    margin-left: 0;
    text-align: center;
  }

  .why-choose-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .why-choose-grid .main-feature {
    width: 90%;
    margin-left: -10px;
    max-height: none;
  }

  .secondary-features {
    grid-template-columns: 1fr;
    gap: 12px;
    margin-left: -2%;
  }

  .feature-2{
    max-height: 18vh;
    margin-left: 1%;
    margin-right: -1%;
    max-width: 100%;
    
  }

  .feature-1{
    max-height: none;
    font-size: 12px;
  }

  .feature-3{
    margin-top: -10%;
    max-width: 100%;
    margin-right: -1%;
    margin-left: 1%;
  }

  .testament {
    padding: 2rem 1rem;
  }

  .testament h2 {
    font-size: 1.5rem;
  }

  .testament-grid {
    gap: 1rem;
  }

  .testament-icon {
    width: 3rem;
    height: 3rem;
  }

  .testament-number,
  .testament-text {
    font-size: 1.2rem;
  }
}

/* Laptop (768px - 1199px) */
@media (min-width: 769px) and (max-width: 1199px) {
  .main-page {
    padding: 20px;
  }

  .content {
    margin-left: -20px;
  }

  .content h1 {
    font-size: 4rem;
    margin-top: 8%;
  }

  .content p {
    font-size: 25px;
    margin-top: -20px;
  }

  .Arrow-container img {
    width: 50px;
    height: 50px;
  }

  .chevron {
    max-width: 60px;
  }

  .services-container {
    max-width: 700px;
    padding: 20px;
  }

  .service-card-slider {
    margin-left: 5%;
  }

  .service-card {
    flex: 0 0 90%;
    min-height: 350px;
    padding: 25px;
  }

  .service-title {
    font-size: 28px;
  }

  .services-description {
    font-size: 15px;
  }

  .why-choose-title {
    font-size: 28px;
    margin-left: 5%;
  }

  .why-choose-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .why-choose-grid .main-feature {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .secondary-features {
    grid-template-columns: 1fr 1fr;
    margin-left: 0;
  }

  .testament-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
}

/* Desktop (1200px and above) */
@media (min-width: 1200px) {
  .main-page {
    padding: 30px;
  }

  .content {
    margin-left: -37px;
  }

  .content h1 {
    font-size: 6rem;
    margin-top: 7%;
  }

  .content p {
    font-size: 35px;
    margin-top: -30px;
  }

  .Arrow-container img {
    width: 60px;
    height: 60px;
  }

  .chevron {
    max-width: 80px;
  }

  .services-container {
    max-width: 900px;
  }

  .service-card-slider {
    margin-left: 20%;
  }

  .service-card {
    flex: 0 0 80%;
  }

  .service-title {
    font-size: 32px;
  }

  .services-description {
    font-size: 1rem;
  }

  .why-choose-title {
    font-size: 32px;
    margin-left: 7%;
  }

  .why-choose-grid {
    grid-template-columns: 1fr 1fr;
    max-width: 1200px;
  }

  .why-choose-grid .main-feature {
    width: 370px;
    margin-left: 15%;
  }

  .secondary-features {
    margin-left: -5%;
  }

  .testament-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
  }
}