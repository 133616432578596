.services-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  justify-content: space-between;
  margin-top: 19px;
}

.services-title {
  position: relative;
  color: #011857; /* White text for better contrast */
  padding: 10px 20px; /* Adjust padding */
  border-radius: 50px;
  border: solid 2px #3f0c4e; /* Defined border thickness */
  font-size: 22px; /* Slightly larger font */
  font-weight: bolder; /* Make text bold */
  margin: 40px auto;
  margin-bottom: -50px;
  text-align: center;
  z-index: -1;
  display: inline-block;
  box-shadow: none; /* Box shadow */
  font-family: 'Michroma', sans-serif;
}

.services-container {
  display: flex;
  flex: 1; /* Allows the container to take up available space */
  padding-bottom: 20px;
  flex-direction: column;
  gap: 24px;
  padding: 0px;
  max-width: 900px;
  margin: 0 auto;
  flex-grow: 0;
  /* Allow the container to grow and push the footer down */
}

.service-card {
  display: flex;
  flex-direction: row;
  background: linear-gradient(145deg, #082157, #043783);
  border-radius: 30px;
  box-shadow: 8px 8px 16px #363434, -8px -8px 16px #ffffff;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 100%;
  min-height: 370px; /* Ensure minimum height */
  padding: 20px;
  margin-bottom: 20px;
  margin-top: -10px;
}

.service-card:hover {
  transform: scale(1.02);
  box-shadow: 10px 10px 20px #c4c4c4, -10px -10px 20px #ffffff;
}

.service-image {
  flex: 1; /* Adjust the flex value to make the image section wider */
  overflow: hidden;
  background-size: cover;
  background-position: center;
  border-radius: 10px; /* Add border-radius to match the design */
  margin-right: 10px; /* Add margin to create space between image and content */
}

.service-image img {
  width: 100%; /* Ensure the image takes the full width of its container */
  height: 100%;
  object-fit: cover;
  border-radius: 20px; /* Add border-radius to match the design */
}

.service-content {
  flex: 2; /* Adjust the flex value to make the content section narrower */
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent; /* Make the background transparent */
}

.service-title {
  font-size: 32px;
  margin: 0;
  font-weight: bold;
  font-family: 'Michroma', sans-serif;
  color: rgb(250, 250, 250); /* Change text color to white */
}

.services-description {
  font-size: 1rem;
  line-height: 1.5;
  color: white;
  margin: 16px 0;
  font-family: 'Michroma', sans-serif;
  font-weight: normal;
}

.service-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details-button {
  background: linear-gradient(90deg, #000000, #181818);
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 10px;
  padding: 5px 15px;
  cursor: pointer;
  font-family: 'Michroma', sans-serif;
  transition: opacity 0.3s, transform 0.3s;
  font-size: 18px;
  line-height: 1.2;
  position: relative;
}

.details-button:hover {
  opacity: 0.9;
  transform: scale(1.05);
  background-color: white;
}

.emoji-arrow {
  position: absolute;
  right: -40px;
  top: -4%;
  transform: translateY(-50%);
  font-size: 28px;
  animation: slideIn 3s infinite, bounce 5s infinite;
  pointer-events: none; /* Prevents the arrow from interfering with clicks */
}

.icon-container {
  background-color: #000000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(128, 0, 128, 0.3);
}

.icon {
  color: white;
  font-size: 18px;
}

/* Media Queries */

/* Mobile (up to 767px) */
@media (max-width: 767px) {
  .services-page {
    padding: 10px;
  }

  .services-title {
    font-size: 18px;
    padding: 8px 16px;
    margin: 20px auto;
    margin-top: 50px;
    margin-left: 27%;
  
  }

  .services-container {
    gap: 16px;
    max-width: 100%;
    padding: 0;
  }

  .service-card {
    flex-direction: column;
    min-height: auto;
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 15px;
    width: 330px;
  }

  .service-image {
    margin-right: 0;
    margin-bottom: 15px;
    border-radius: 20px 20px 0 0;
  }

  .service-image img {
    border-radius: 20px 20px 0 0;
  }

  .service-content {
    padding: 10px;
  }

  .service-title {
    font-size: 24px;
  }

  .services-description {
    font-size: 14px;
  }

  .service-footer {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .details-button {
    width: 120%;
    padding: 6px 12px;
    font-size: 16px;
  }

  .emoji-arrow {
    right: -20px;
    font-size: 20px;
  }

  .icon-container {
    width: 30px;
    height: 30px;
  }

  .icon {
    font-size: 14px;
  }
}

/* Laptop (768px - 1199px) */
@media (min-width: 768px) and (max-width: 1199px) {
  .services-page {
    padding: 15px;
  }

  .services-title {
    font-size: 20px;
    padding: 9px 18px;
    margin: 30px auto;
  }

  .services-container {
    gap: 20px;
    max-width: 800px;
  }

  .service-card {
    padding: 18px;
    margin-top: 25px;
    margin-bottom: 18px;
  }

  .service-image {
    margin-right: 15px;
  }

  .service-content {
    padding: 15px;
  }

  .service-title {
    font-size: 28px;
  }

  .services-description {
    font-size: 15px;
  }

  .details-button {
    padding: 6px 14px;
    font-size: 17px;
  }

  .emoji-arrow {
    right: -30px;
    font-size: 24px;
  }

  .icon-container {
    width: 35px;
    height: 35px;
  }

  .icon {
    font-size: 16px;
  }
}

/* Desktop (1200px and above) */
@media (min-width: 1200px) {
  .services-page {
    padding: 20px;
  }

  .services-title {
    font-size: 22px;
    padding: 10px 20px;
    margin: 40px auto;
  }

  .services-container {
    gap: 24px;
    max-width: 900px;
  }

  .service-card {
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .service-image {
    margin-right: 20px;
  }

  .service-content {
    padding: 20px;
  }

  .service-title {
    font-size: 32px;
  }

  .services-description {
    font-size: 1rem;
  }

  .details-button {
    padding: 5px 15px;
    font-size: 18px;
  }

  .emoji-arrow {
    right: -40px;
    font-size: 28px;
  }

  .icon-container {
    width: 40px;
    height: 40px;
  }

  .icon {
    font-size: 18px;
  }
}