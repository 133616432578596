/* Hero Section */
.hero-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  margin-top: 3.5rem;
  margin-right: 5%;
}

.hero-text {
  max-width: 43rem;
  line-height: 2;
  margin-left: 5rem;
}

.hero-title {
  font-size: 2.5rem;
  font-weight: bold;
}

.hero-gradient {
  background: linear-gradient(to right, #d13bf6, #9333ea, #ef4444);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3rem;
}

.hero-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.hero-titles {
  font-size: 1.5rem;
  font-weight: bold;
  color: black;
}

.hero-subtext {
  font-size: 1rem;
  color: #6b7280;
}

.hero-button {
  margin-top: 1.5rem;
  padding: 10px 20px;
  border: 2px solid #0b204e;
  color: rgb(0, 0, 0);
  font-size: large;
  font-family: 'Michroma', sans-serif;
  font-weight: 600;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
  margin-left: 13rem;
}

.hero-button:hover {
  background-color: #031346;
  color: #ffffff;
}

.hero-image {
  position: relative;
  margin-right: -1rem;
  max-width: 35rem;
  max-height: 45rem;
  margin-top: 2rem;
}

.hero-img {
  max-width: 35rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* On-Field Action Section */
.on-field-container {
  text-align: center;
  margin-top: 2rem;
  padding: 1rem;
}

.section-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.slideshow-container {
  position: relative;
  width: 80%;
  max-width: 800px;
  margin: auto;
}

.slideshow-img {
  width: 100%;
  max-width: 800px;
  max-height: 500px;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.nav-button {
  position: absolute;
  top: 50%;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 2rem;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 50%;
  transition: background 0.3s;
}

.nav-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.nav-button.prev {
  left: 10px;
  transform: translateY(-50%);
}

.nav-button.next {
  right: 10px;
  transform: translateY(-50%);
}

/* Tailored Courses Section */
.courses-container {
  text-align: center;
  margin-top: 4rem;
  padding: 2rem;
}

.courses-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 1.5rem;
  max-width: 1100px;
  margin: auto;
}

.course-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    text-align: left;
    transition: transform 0.3s;
    height: 100%; /* important for equal height cards */
  }
  

.course-card:hover {
  transform: translateY(-5px);
}

.course-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.course-content {
  padding: 1rem;
  flex-grow: 1; /* this makes it fill available space */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.course-title {
  font-size: 18px;
  font-weight: normal;
  font-family: 'Michroma', sans-serif;
}

.course-description {
  font-size: 14px;
  color: #555;
  line-height: 1.8;
  margin: 0.5rem 0;
  font-family: 'Michroma', sans-serif;
}

.course-duration {
  font-size: 10px;
  font-family: 'Michroma', sans-serif;
  font-weight: bold;
  color: #08245f;
}

.course-button {
  background-color: #c3c4c7;
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-family: 'Michroma', sans-serif;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 12px;
  margin-top: 1rem;
  display: block;
  width: fit-content;
}

.course-button:hover {
  background-color: #081a55;
  color: #ffffff;
}

/* Why Learn to Fly with HDS Section */
.why-learn-container {
  text-align: center;
  margin-top: 4rem;
  padding: 2rem;
}

.why-learn-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1100px;
  margin: auto;
  margin-top: 2rem;
}

.why-box {
  flex: 1;
  text-align: center;
  padding: 1rem;
}

.why-box h3 {
  font-size: 24px;
  font-weight: bold;
  font-family: 'Michroma', sans-serif;
}

.why-box ul {
  list-style: none;
  padding: 0;
}

.why-box li {
  font-size: 16px;
  color: #333;
  margin: 0.5rem 0;
  text-align: justify;
  text-align: start;
  margin-left: 10rem;
  font-family: 'Michroma', sans-serif;
}

.why-img {
  width: 40%;
  border-radius: 8px;
}

.why-box .join-button {
  background-color: #e4e5e7;
  border: 2px solid rgb(5, 25, 63);
  color: rgb(0, 0, 0);
  font-size: 1rem;
  font-family: 'Michroma', sans-serif;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 12px;
  margin-top: 1rem;
  display: inline-block;
}

.join-button:hover {
  background-color: #051d6b;
  color: #ffffff;
}

/* Testimonials Section */
.testimonials-container {
  background: linear-gradient(to right, #0a59c7, #1417be);
  color: white;
  text-align: center;
  padding: 2rem 1.5rem;
  margin-top: 2rem;
  border-radius: 10px;
}

.testimonials-slider {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.testimonial-card {
  background: white;
  color: #020202;
  width: 300px;
  padding: 1.5rem;
  border-radius: 10px;
  text-align: left;
  font-family: 'Michroma';
  font-size: 14px;
  line-height: 1.6;
  transition: transform 0.5s ease;
}

.testimonial-quote {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.testimonial-name {
  font-size: 1.1rem;
  font-weight: bold;
}

.testimonials-controls {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.testimonial-arrow {
  background: transparent;
  border: 2px solid white;
  color: white;
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
  border-radius: 5px;
}

.testimonial-arrow:hover {
  background: white;
  color: #0047ab;
}

/* Career Opportunities Section */
.career-opportunities-container {
  text-align: center;
  padding: 40px 0;
}

.career-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  max-width: 1200px;
  margin: auto;
}

.career-benefits {
  display: flex;
  flex-direction: column;
  gap: 55px;
}

.career-item {
  display: flex;
  margin-left: 8rem;
  align-items: center;
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid;
  font-family: 'Michroma', sans-serif;
  font-size: 16px;
}

.career-item img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

.career-benefits .career-image img {
  width: 80%;
  height: 300px;
  margin-right: 10rem;
}

.join-button {
  background-color: #e0e0e0;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  margin-top: 20px;
  border-radius: 12px;
  font-family: 'Michroma', sans-serif;
  cursor: pointer;
}

.join-button:hover {
  background-color: #091769;
  color: #f8f8f8;
}

/* FAQ Section */
.faq-container {
  max-width: 800px;
  margin: 40px auto;
  text-align: center;
}

.faq-item {
  background: #f9f9f9;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-family: 'Michroma', sans-serif;
}

.faq-arrow {
  font-size: 20px;
}

.faq-answer { /* Fixed selector from .faq-question .faq-answer */
  margin-top: 10px;
  font-size: 14px;
  color: #555;
  transition: all 0.3s ease-in-out;
  font-family: 'Michroma', sans-serif; /* Fixed typo: 'Michoma' to 'Michroma' */
}

/* Media Queries */

/* Mobile (up to 767px) */
@media (max-width: 767px) {
  .hero-container {
    flex-direction: column;
    padding: 1rem;
    margin-top: 2rem;
  }

  .hero-text {
    margin-left: 0;
    text-align: center;
    max-width: 100%;
  }

  .hero-title {
    font-size: 1.8rem;
  }

  .hero-info {
    gap: 1.5rem;
    justify-content: center;
  }

  .hero-button {
    margin-left: 0;
    padding: 8px 16px;
    font-size: medium;
  }

  .hero-image {
    margin-right: 0;
    max-width: 100%;
    margin-top: 1.5rem;
  }

  .hero-img {
    max-width: 100%;
  }

  .on-field-container {
    padding: 0.5rem;
  }

  .section-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .slideshow-container {
    width: 90%;
    max-width: 500px;
  }

  .slideshow-img {
    max-height: 300px;
  }

  .nav-button {
    font-size: 1.5rem;
    padding: 0.3rem 0.8rem;
  }

  .courses-container {
    padding: 1rem;
    margin-top: 2rem;
  }

  .courses-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .course-card {
    max-width: 100%;
  }

  .course-img {
    height: 150px;
  }

  .why-learn-container {
    padding: 1rem;
    margin-top: 2rem;
  }

  .why-learn-content {
    flex-direction: column;
    gap: 1.5rem;
  }

  .why-box {
    padding: 0.5rem;
  }

  .why-box li {
    margin-left: 0;
    text-align: center;
  }

  .why-img {
    width: 80%;
  }

  .testimonials-container {
    padding: 1rem;
  }

  .testimonials-slider {
    flex-direction: column;
    gap: 1rem;
  }

  .testimonial-card {
    width: 87%;
  }

  .career-opportunities-container {
    padding: 20px 0;
  }

  .career-content {
    flex-direction: column;
    gap: 10px;
  }

  .career-benefits {
    gap: 20px;
  }

  .career-item {
    margin-left: 0;
    font-size: 14px;
  }

  .career-item img {
    width: 30px;
    height: 30px;
  }

  .career-image img {
    width: 80%;
    margin-right: 0;
  }

  .faq-container {
    padding: 0 1rem;
  }

  .faq-item {
    padding: 10px;
  }

  .faq-question {
    font-size: 14px;
  }

  .faq-arrow {
    font-size: 16px;
  }

  .faq-answer {
    font-size: 12px;
  }
}

/* Laptop (768px - 1199px) */
@media (min-width: 768px) and (max-width: 1199px) {
  .hero-container {
    padding: 1.5rem;
  }

  .hero-text {
    margin-left: 2rem;
    max-width: 35rem;
  }

  .hero-title {
    font-size: 2.2rem;
  }

  .hero-info {
    gap: 2rem;
  }

  .hero-button {
    margin-left: 5rem;
    padding: 9px 18px;
  }

  .hero-image {
    max-width: 30rem;
  }

  .hero-img {
    max-width: 30rem;
  }

  .on-field-container {
    padding: 1rem;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .slideshow-container {
    width: 85%;
    max-width: 600px;
  }

  .slideshow-img {
    max-height: 400px;
  }

  .courses-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.2rem;
  }

  .course-img {
    height: 180px;
  }

  .why-learn-content {
    gap: 1rem;
  }

  .why-box li {
    margin-left: 5rem;
  }

  .why-img {
    width: 45%;
  }

  .testimonials-slider {
    gap: 1rem;
  }

  .testimonial-card {
    width: 250px;
  }

  .career-content {
    gap: 15px;
  }

  .career-benefits {
    gap: 40px;
  }

  .career-item {
    margin-left: 4rem;
  }

  .career-image img {
    width: 250px;
    margin-right: 5rem;
  }
}

/* Desktop (1200px and above) */
@media (min-width: 1200px) {
  .hero-container {
    padding: 2rem;
  }

  .hero-text {
    max-width: 43rem;
    margin-left: 5rem;
  }

  .hero-title {
    font-size: 2.5rem;
  }

  .hero-button {
    margin-left: 13rem;
  }

  .hero-image {
    max-width: 35rem;
  }

  .hero-img {
    max-width: 35rem;
  }

  .on-field-container {
    padding: 1rem;
  }

  .section-title {
    font-size: 2rem;
  }

  .slideshow-container {
    width: 80%;
    max-width: 800px;
  }

  .slideshow-img {
    max-height: 500px;
  }

  .courses-grid {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 1.5rem;
    max-width: 1100px;
  }

  .course-img {
    height: 200px;
  }

  .why-learn-content {
    max-width: 1100px;
  }

  .why-box li {
    margin-left: 10rem;
  }

  .why-img {
    width: 40%;
  }

  .testimonials-slider {
    gap: 1.5rem;
  }

  .testimonial-card {
    width: 300px;
  }

  .career-content {
    max-width: 1200px;
  }

  .career-benefits {
    gap: 55px;
  }

  .career-item {
    margin-left: 8rem;
  }

  .career-image img {
    width: 300px;
    margin-right: 10rem;
  }
}