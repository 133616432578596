/* Scrolling Text */
.scrolling-text-container {
  width: 100%;
  overflow: hidden;
  background: rgb(31, 54, 104);
  padding: 5px 0;
  white-space: nowrap;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100; /* Keeps it above navbar but below toggle button */
  display: flex;
  letter-spacing: 1px;
  align-items: center;
}

.scrolling-text-container .scrolling-text {
  display: inline-block;
  white-space: nowrap;
  color: #ffffff;
  font-size: 12px;
  animation: scrollText 50s linear infinite;
  line-height: normal;
}

.scrolling-text a {
  color: #ffffff;
  text-decoration: none;
  font-family: 'Michroma', sans-serif;
  margin-right: 30px;
  padding: 5px 10px;
  display: inline-block;
}

.scrolling-text a:hover {
  color: #7ebff5;
}

/* Duplicate text using ::after */
.scrolling-text::after {
  content: attr(data-text);
  position: absolute;
  left: 100%;
  white-space: nowrap;
}

@keyframes scrollText {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.navbar-container {
  position: fixed;
  top: 20px;
  left: 0;
  width: 100vw;
  height: 9%;
  background: rgba(255, 255, 255, 1.5);
  color: #000;
  padding: 10px 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000; /* Ensures navbar is below toggle button */
}

.navbar.scrolled {
  background: white;
}

/* Logo */
.logo-container {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.logo-container .logo {
  height: 2.8rem;
  width: 2.8rem;
  margin-right: 3px;
  margin-top: 12px;
  margin-left: 10px;
}

.logo-container .company-name {
  font-size: 1.3rem;
  margin-top: 12px;
  font-weight: bolder;
  color: rgb(0, 0, 0);
}

/* Toggle Button (hidden by default, shown on mobile) */
.mobile-toggle {
  display: none; /* Hidden on larger screens */
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #000;
  padding: 5px;
  z-index: 1200; /* Ensures toggle is above navbar and scrolling text */
}

/* Navigation Content */
.nav-content {
  display: flex;
  align-items: center;
}

/* Navigation Links */
.navbar-container .nav-links {
  list-style: none;
  display: flex;
  gap: 15px;
  margin-top: 30px;
}

.navbar-container .nav-links li a,
.dropdown-btn {
  color: black;
  text-decoration: none;
  font-size: 14.5px;
  font-family: 'Michroma', sans-serif;
  padding: 5px;
  /* transition: color 0.3s ease-in-out, font-weight 0.3s ease-in-out; */
}

.nav-links li a:hover {
  font-weight: bold;
  color: #053872;
  /* transition: 0.3s; */
}

.dropdown:hover .dropdowns-menu,
.dropdown:focus-within .dropdowns-menu {
  display: flex;
   /* Show when hovering */
}

.dropdowns-menu li a {
  transition: none !important;
}
/* Dropdown Menu */
.dropdown {
  position: relative;
}

.dropdowns-menu {
  position: absolute;
  top: 120%;
  top: calc(100% + 5px); /* Push it slightly below the nav item */
  z-index: 1200;
  margin-left: -8%;
  background-color: rgb(250, 250, 250);
  color: black;
  list-style: none;
  width: 150%;
  display: flex;
  flex-direction: column;
  font-size: 7px;
  text-align: left;
  padding-left: 0;
  z-index: 1200;
}

.dropdowns-menu li {
  padding: 0;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
  text-align: left;
}

.dropdowns-menu li a {
  color: black;
  text-decoration: none;
}

.dropdowns-menu li a:hover {
  display: block;
  /* transition: 0.3s; */
}
.dropdown:hover .dropdowns-menu {
  display: flex;
  /* animation: fadeIn 0.2s ease-in-out; */
}

/* @keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} */

/* Button Group */
.btn-group {
  display: flex;
  gap: -1px;
}

.btn-group .brochure-btn,
.btn-group .connect-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 112px;
  height: 26px;
  color: #000000;
  font-size: 14.5px;
  font-family: 'Michroma', sans-serif;
  padding: 3px 1px;
  border-radius: 10px;
  margin-top: 13px;
  text-decoration: none;
  border: 2px solid #0a4181;
  transition: background-color 0.3s, color 0.3s;
  text-align: center;
  white-space: nowrap;
}

.btn-group .brochure-btn,
.btn-group .connect-btn {
  flex: 1;
  max-width: 130px;
}

.brochure-btn:hover,
.connect-btn:hover {
  background-color: #083d7a;
  color: #ffffff;
}

.btn-group .connect-btn {
  max-height: 8%;
}

.search-container {
  position: relative;
  margin-left: -10%;
  
}

.search-input {
  padding: 7px;
  border-radius: 10px;
  margin-top: 10%;
  margin-left: 3%;
  width: 150px;
  font-size: 14px;
}

.search-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #020b3f;
  max-height: 200px;
  overflow-y: auto;
  z-index: 100;
}

.search-item {
  padding: 8px;
  cursor: pointer;
}

.search-item:hover {
  background-color: #f0f0f0;
}



/* Media Queries */

/* Mobile (up to 767px) */
@media (max-width: 767px) {
  .scrolling-text-container {
    padding: 3px 0;
  }

  .scrolling-text {
    font-size: 10px;
  }

  .scrolling-text a {
    margin-right: 15px;
    padding: 3px 5px;
  }

  .cart-icon {
    font-size: 14px !important; /* Reduce icon size */
    margin-left: 0 !important; /* Adjust margin */
    margin-top: 2% !important;
    margin-bottom: -1% !important;
  }

  @keyframes scrollText {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-150%);
    }
  }

  .navbar-container {
    height: auto;
    padding: 10px;
    top: 25px;
    width: 100vw;
    flex-wrap: wrap;
  }

  .logo-container {
    margin-bottom: 10px;
  }

  .logo-container .logo {
    height: 2rem;
    width: 2rem;
    margin-top: 5px;
    margin-left: 5px;
  }

  .logo-container .company-name {
    font-size: 1rem;
    margin-top: 5px;
  }

  /* Show toggle button on mobile */
  .mobile-toggle {
    display: block;
    color: #000;
    position: absolute;
    right: 40px;
    top: 50%; /* Center vertically in the navbar */
    transform: translateY(-50%); /* Adjust for perfect centering */
  }

  /* Navigation content hidden by default on mobile */
  .nav-content {
    display: none;
    flex-direction: column;
    width: 100%;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    position: absolute;
    top: 100%; /* Positions below the navbar */
    left: 0;
  }

  /* Show navigation content when open */
  .nav-content.open {
    display: flex;
  }

  .navbar-container .nav-links {
    flex-direction: column;
    gap: 5px;
    margin-top: 0;
    width: 100%;
    align-items: center;
  }

  .navbar-container .nav-links li {
    width: 75%;
    text-align: right;
  }

  .navbar-container .nav-links li a,
  .dropdown-btn {
    font-size: 12px;
    padding: 10px;
    display: block;
  }

  .dropdown {
    position: static;
  }

  .dropdowns-menu {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #f5f5f5;
    margin-top: 5px;
    font-size: 10px;
    padding: 5px 0;
  }

  .dropdowns-menu li {
    margin: 5px 0;
    text-align: end;
  }

  .btn-group {
    flex-direction: column;
    gap: 10px;
    width: 80%;
    align-items: end;
    margin-top: 10px;
  }

  .btn-group .brochure-btn,
  .btn-group .connect-btn {
    font-size: 12px;
    padding: 5px 10px;
    min-width: 100px;
    margin: 0;
  }

  .search-container {
    display: none;
  }

}

/* Laptop (768px - 1199px) */
@media (min-width: 768px) and (max-width: 1199px) {
  .scrolling-text-container {
    padding: 4px 0;
  }

  .scrolling-text {
    font-size: 11px;
  }

  .scrolling-text a {
    margin-right: 20px;
    padding: 4px 8px;
  }

  .navbar-container {
    height: 8%;
    padding: 8px 3%;
    top: 20px;
    width: 94vw;
  }

  .logo-container .logo {
    height: 2.5rem;
    width: 2.5rem;
    margin-top: 8px;
  }

  .logo-container .company-name {
    font-size: 1.2rem;
    margin-top: 8px;
  }

  .navbar-container .nav-links {
    gap: 12px;
    margin-top: 20px;
  }

  .navbar-container .nav-links li a,
  .dropdown-btn {
    font-size: 13px;
    padding: 4px;
  }

  .dropdowns-menu {
    font-size: 6.5px;
  }

  .btn-group {
    gap: 5px;
  }

  .btn-group .brochure-btn,
  .btn-group .connect-btn {
    font-size: 13px;
    min-width: 100px;
    padding: 3px 8px;
  }
  .search-container {
    display: none;
  }
}

/* Desktop (1200px and above) */
@media (min-width: 1200px) {
  .scrolling-text-container {
    padding: 5px 0;
  }

  .scrolling-text {
    font-size: 12px;
  }

  .scrolling-text a {
    margin-right: 30px;
    padding: 5px 10px;
  }

  .navbar-container {
    height: 9%;
    padding: 10px 20px;
    top: 20px;
    width: 97vw;
  }

  .logo-container .logo {
    height: 2.8rem;
    width: 2.8rem;
    margin-top: 12px;
  }

  .logo-container .company-name {
    font-size: 1.3rem;
    margin-top: 12px;
  }

  .navbar-container .nav-links {
    gap: px;
    margin-top: 30px;
    margin-left: -19%;
    margin-right: 20px;
  }

  .navbar-container .nav-links li a,
  .dropdown-btn {
    font-size: 14.5px;
    padding: 5px;
  }

  .dropdowns-menu {
    font-size: 7px;
  }

  .nav-content .btn-group {
    gap: 15px;
    margin-left: 25px;
    margin-right: -8rem;
  }

  .btn-group .brochure-btn,
  .btn-group .connect-btn {
    font-size: 14.5px;
    min-width: 112px;
    padding: 3px 1px;
  }

   
  
}