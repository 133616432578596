.agriculture-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.8);
  margin-left: 4%;
  margin-top: 2%;
}

.alines-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  pointer-events: none;
}

.alines-svg {
  width: 90%;
  height: 90%;
}

.agriculture-path {
  stroke: #2e7d32; /* Dark green for an agriculture theme */
  stroke-width: 1.8px;
  stroke-linejoin: miter;
  fill: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.agriculture-path.animated {
  opacity: 1;
}

.agriculture-button {
  width: 100%;
  height: 100%;
  background: transparent;
  border: 2px solid transparent;
  border-color: rgb(46, 125, 50); /* Matching green border */
  color: rgb(3, 3, 3);
  font-family: 'Michroma', sans-serif;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.3s ease-in-out;
  pointer-events: auto;
  position: relative;
  cursor: pointer;
}

.agriculture-button::before {
  content: "";
  position: absolute;
  inset: -2px;
  mask-composite: exclude;
}

.agriculture-button:hover {
  color: #ffffff;
  background: #4caf50; /* Lighter green for hover effect */
}

.center-logo {
  fill: #fff;
  stroke: #00aaff; /* Keeping the blue stroke from Surveillance */
  stroke-width: 2;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.agriculture-path.animated {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 2s forwards ease-out;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

.button-animated {
  opacity: 0;
  animation: fadeIn 1s forwards ease-out;
}

/* Media Queries */
@media (max-width: 767px) {
  .agriculture-container {
    transform: scale(0.8); /* Matches inspection page scale */
    margin: 0 auto;
    margin-left: -40px;
    margin-top: 1%;
    height: 60vh; /* Matches inspection page height */
    width: 110%; /* Ensure the container takes full width */
    padding-right: 0; /* Remove any right padding */
  }

  .alines-container {
    position: absolute;
    width: 100%; /* Ensure full width */
    height: 100%;
    top: 0;
    left: 30px;
    z-index: 3;
    pointer-events: none;
    padding-right: 0; /* Remove any right padding */
    margin-right: 0; /* Remove any right margin */
  }

  .alines-svg {
    width: 32rem; /* Matches inspection page SVG width */
    height: 32rem; /* Matches inspection page SVG height */
    margin: 0; /* Remove any margins */
    margin-left: -10%; /* Matches inspection page margin-left */
    padding: 0; /* Remove any padding */
  }

  .agriculture-path {
    stroke-width: 2px; /* Matches inspection page stroke width */
  }

  .agriculture-button {
    font-size: 8px; /* Matches inspection page button font size */
    max-width: 140px; /* Matches inspection page button width */
    padding: 4px 5px; /* Matches inspection page button padding */
  }



  .center-logo {
    height: 30%;
    width: 30%;
    x: 360;
    y: 160;
  }
}

/* Tablet/Small Laptop (768px - 900px) */
@media (min-width: 768px) and (max-width: 900px) {
  .agriculture-container {
    transform: scale(0.65);
    margin-left: 2%;
    margin-top: 1%;
  }

  .alines-svg {
    width: 95%;
    height: 95%;
  }

  .agriculture-button {
    font-size: 10px;
    min-width: 100px;
    padding: 6px;
  }

  .agriculture-path {
    stroke-width: 1.5px;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1200px) {
  .agriculture-container {
    transform: scale(0.75);
    margin-left: 3%;
    margin-top: 1.5%;
  }

  .alines-svg {
    width: 90%;
    height: 90%;
  }

  .agriculture-button {
    font-size: 11px;
    min-width: 120px;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1600px) {
  .agriculture-container {
    transform: scale(0.8);
    margin-left: 4%;
    margin-top: 2%;
  }

  .alines-svg {
    width: 90%;
    height: 90%;
  }

  .agriculture-button {
    font-size: 12px;
    min-width: 130px;
  }
}

@media only screen and (min-width: 1601px) {
  .agriculture-container {
    transform: scale(0.8);
    margin-left: 5%;
    margin-top: 2.5%;
  }

  .alines-svg {
    width: 85%;
    height: 85%;
  }

  .agriculture-button {
    font-size: 14px;
    min-width: 140px;
  }
}