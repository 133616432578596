body {
  overflow-x: hidden;
}

.contact-page {
  font-family: 'Michroma', sans-serif;
  margin-top: 10px;
  min-height: 100vh; 
  /* max-width: 1250px; */
  display: flex;
  flex-direction: column;
}

.contact-title {
  font-size: 1.4rem;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5%;
  text-transform: uppercase;
}

.contact-container {
  margin-top: 5%;
  max-width: auto;
  margin-left: 1.5%;
}

.contact-container .contact-form {
  display: flex;
  flex-direction: column;
  background-color: #041b38;
  margin-top: 2%;
  margin-bottom: 30px;
  padding: 30px;
  max-width: 1430px;
  max-height: 450px;
  box-shadow: 0 0 20px rgba(138, 43, 226, 0.2);
  gap: 30px;
}

.contact-head h1 {
  font-size: 2rem;
  font-family: 'Michroma', sans-serif;
  color: rgb(255, 255, 255);
  text-align: left;
  display: inline-block;
  text-transform: capitalize;
  margin-top: 30px;
  margin-left: 40px;
}

.contact-form .contact-head h1 span {
  color: #1a69d1;
}

.contact-form .contact-head p {
  font-size: 11px;
  font-family: 'Michroma', sans-serif;
  color: #adadad;
  margin-left: 0px;
  margin-top: -5px;
  text-align: justify;
  max-width: 490px;
}

.forms {
  flex: 1;
  margin-top: -25%;
  margin-left: 30%;
}

.form-group {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  color: white;
  margin-left: 20%;
  margin-top: 3%;
}

.form-group label {
  width: 100px;
  margin-right: 20px;
  font-size: 18px;
  margin-top: 1.7%;
  gap:20px;
  color: rgb(220, 222, 223);
}

.separator {
  color: white;
  margin: 11px 40px 0 30px;
}

.form-group input,
.form-group textarea {
  flex: 1;
  padding: 13px;
  background-color: #cec5c5;
  border: none;
  margin-top: 5px;
  max-width: 60%;
  margin-left: -5px;
  border-radius: 5px;
}

.form-group textarea {
  height: 85px;
  resize: none;
}

.form-group input:hover,
.form-group textarea:hover {
  background-color: #b3a7a7; /* Darker shade */
}

.forms button[type="submit"] {
  float: center;
  margin-left: 62%;
  padding: 8px 30px;
  background: linear-gradient(90deg, #d1d2d3, #d4d6df);
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  font-family: 'Michroma', sans-serif;
}

button[type="submit"]:hover {
  background: linear-gradient(90deg, #0374b6, #0b6bac);
  color: #fff;
  transform: scale(1.05);
}

/* New Section Styles */
.contact-info {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  padding: 10px;
  border: none;
  margin-bottom: 10px;
  height: 60px;
}

.contact-text {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 5%;
  margin-left: 10px;
  margin-top: 5%;
}

.contact-text .contact-title{
  font-size: 2.5rem;
  font-family: 'Michroma', sans-serif;
  color: rgb(48, 48, 48);
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 1%;
}

.contact-text h1 span {
  color: #1a69d1;
}

.contact-info .contact-text  p {
  font-size: 15px;
  text-align: center;
  margin-left: 13%;
  margin-right: 20%;
  margin-bottom: -6%;
  width: 70%;
  font-family:'Michroma',sans-serif;
}

/* Media Queries */

/* Mobile (up to 767px) */
@media (max-width: 767px) {
  .contact-page {
    width: 90%;
    padding: 10px;
    margin-top: 29%;
  }

  .contact-info .contact-title {
    font-size: 28px;
    margin-top: 7%;
  }

  .contact-info .contact-text p{
    max-width: 70%;
    margin-bottom: 10%;
  }

  .contact-container {
    margin-top: 10%;
  }

  .contact-container .contact-form {
    padding: 20px;
    max-height: 170vh;
    max-width: 110%;
    margin-left: 4%;
    margin-top: 10%;
  }

  .contact-head h1 {
    font-size: 2rem;
    margin-left: 0;
    text-align: center;
  }

  .contact-form .contact-head p {
    font-size: 14px;
    max-width: 100%;
  }

  .forms {
    margin-top: -5%;
    margin-left: 6%;
  }

  .form-group {
    flex-direction: column;
    align-items: center;
    margin-left: -6%;
    margin-top: 10px;
  }

  .form-group label {
    width: auto;
    margin-bottom: 1px;
    font-size: 16px;
  }

  .separator {
    display: none;
  }

  .form-group input,
  .form-group textarea {
    max-width: 90%;
    width: 90%;
    margin-left: 0;
  }

  .forms button[type="submit"] {
    margin-left: 15%;
    width: 60%;
    padding: 10px;
  }

  .contact-info {
    align-items: center;
  }

  .contact-text {
    margin-left: 0;
    text-align: center;
  }

  .contact-text h1 {
    font-size: 1.5rem;
  }

  .contact-info .contact-text p {
    font-size: 13px;
    margin-top: 5%;
    margin-right: 25px;
  }
}

/* Laptop (768px - 1199px) */
@media (min-width: 768px) and (max-width: 1199px) {
  .contact-page {
    width: 90%;
    padding: 15px;
  }

  .contact-title {
    font-size: 24px;
  }

  .contact-container {
    margin-top: 8%;
  }

  .contact-container .contact-form {
    max-width: 90%;
    margin-left: 40%;
    height: auto;
    padding: 25px;
  }

  .contact-head h1 {
    font-size: 2.5rem;
    margin-left: 20px;
  }

  .contact-form .contact-head p {
    font-size: 16px;
  }

  form {
    margin-top: 10%;
    margin-left: 20%;
  }

  .form-group {
    margin-left: 10%;
  }

  .form-group label {
    font-size: 14px;
    margin-bottom: 5%;
  }

  .form-group input,
  .form-group textarea {
    max-width: 60%;
  }

  button[type="submit"] {
    margin-left: 35%;
    padding: 5px 15px;
  }

  .contact-text h1 {
    font-size: 2.5rem;
  }

  .contact-text p {
    font-size: 18px;
    margin-right: 20%;
  }
}

/* Desktop (1200px and above) */
@media (min-width: 1200px) {
  .contact-page {
    /* width: 1350px; */
    padding: 20px;
    margin-left: 5%;
  }

  .contact-title {
    font-size: 36px;
  }

  .contact-container {
    margin-top: 5%;
  }

  .contact-container .contact-form {
    max-width: 1230px;
    max-height: 560px;
    padding: 30px;
    margin-bottom: 5%;
  }

  .contact-head h1 {
    font-size: 3rem;
    margin-left: 40px;
  }

  .contact-form .contact-head p {
    font-size: 18px;
  }

  form {
    margin-top: -15%;
    margin-left: 45%;
  }

  .form-group {
    margin-left: 20%;
  }

  .form-group label {
    font-size: 18px;
  }

  .form-group input,
  .form-group textarea {
    max-width: 60%;
  }

  button[type="submit"] {
    margin-left: 55%;
    padding: 8px 30px;
  }

  .contact-text h1 {
    font-size: 3rem;
  }

  .contact-text p {
    font-size: 20px;
    margin-right: 40%;
  }

}