.surveillance-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: auto;
  padding: 10px;
  margin-top: 30px;
}

section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px; /* decreased from 360px */
  margin: 15px 0; /* reduced spacing between sections */
  padding: 10px;
  gap: 5px;
  line-height: 1.6;
}

 section img {
  height: 80%;
  width: 80%;
  border-radius: 10px;
  padding: 20px;
}

img{
  border-radius: 10px;
}

section .content {
  width: 70%;
  height: 380px;
  padding: 20px;
  margin-top: 5%;
}

section .content h2 {
  margin-top: 15%;
  font-size: 1rem;
  color: #014c97;
  margin-bottom: 50px;
  font-family: 'Michroma', sans-serif;
}

section .content p {
  font-size: 16px;
  color: #000000;
  text-align: justify;
  font-family: 'Michroma', sans-serif;
  line-height: 1.5;
}

/* Rowwise */
.wild-surveillance img {
  margin-left: 6%;
}

.cm-surveillance img {
  margin-left: 6%;
}

.wild-surveillance .content {
  margin-right: 6%;
}

.cm-surveillance .content {
  margin-right: 6%;
}

/* Row-reverse */
.flood-surveillance img {
  margin-right: 6%;
}

.security-surveillance img {
  margin-right: 6%;
}

.flood-surveillance .content {
  margin-left: 6%;
}

.security-surveillance .content {
  margin-left: 6%;
}

/* New Media Queries */
@media only screen and (max-width: 767px) {
  .surveillance-details-container {
    padding: 1px;
    margin-top: 45px;
  }

  section {
    flex-direction: column;
    height: auto;
    margin: 20px 10px;
    padding: 10px;
    gap: 2%;
  }

  section img {
    width: 100%; /* Adjusted for better responsiveness */
    padding: 10px;
    margin: 0 auto; /* Center align */
    display: block;
  }

  section .content {
    width: 100%;
    height: auto;
    padding: 15px;
  }

  section .content h2 {
    font-size: 1.4rem; /* Slightly larger for better readability */
    margin-top: 5%;
    margin-bottom: 1px;
  }

  section .content p {
    font-size: 14px; /* Increased for readability */
    line-height: 1.5;
    text-align: justify;
  }

  /* Reset margins for mobile */
  .wild-surveillance img,
  .cm-surveillance img,
  .flood-surveillance img,
  .security-surveillance img {
    margin-left: 0;
    margin-right: 0; /* Ensuring images are centered */

  }

  .flood-surveillance .content,
  .security-surveillance .content{
    margin-top: -15%;
  }

  .wild-surveillance .content,
  .cm-surveillance .content,
  .flood-surveillance .content,
  .security-surveillance .content {
    margin-left: 0;
    margin-right: 0;
  }
}


@media only screen and (min-width: 601px) and (max-width: 900px) {
  .surveillance-details-container {
    padding: 10px;
    margin-top: 20px;
  }

  section {
    height: auto;
    margin: 25px 10px;
    gap: 7%;
  }

  section img {
    width: 45%;
    padding: 15px;
  }

  section .content {
    width: 50%;
    height: auto;
    padding: 15px;
  }

  section .content h2 {
    font-size: 1.4rem;
    margin-top: 10%;
    margin-bottom: 30px;
  }

  section .content p {
    font-size: 14px;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1200px) {
  .surveillance-details-container {
    padding: 15px;
  }

  section {
    height: 400px;
    margin: 30px 15px;
    gap: 8%;
  }

  section img {
    width: 40%;
    padding: 15px;
  }

  section .content {
    width: 55%;
    padding: 15px;
  }

  section .content h2 {
    font-size: 1.6rem;
    margin-top: 12%;
    margin-bottom: 40px;
  }

  section .content p {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1600px) {
  .surveillance-details-container {
    padding: 20px;
  }

  section {
    height: 440px;
    margin: 40px 20px;
    gap: 10%;
  }

  section img {
    width: 35%;
    padding: 20px;
  }

  section .content {
    width: 60%;
    padding: 20px;
  }

  section .content h2 {
    font-size: 1.8rem;
    margin-top: 15%;
  }

  section .content p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1601px) {
  .surveillance-details-container {
    padding: 30px;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }

  section {
    height: 500px;
    margin: 50px 30px;
    gap: 12%;
  }

  section img {
    width: 30%;
    padding: 25px;
  }

  section .content {
    width: 65%;
    padding: 25px;
  }

  section .content h2 {
    font-size: 2rem;
    margin-top: 15%;
    margin-bottom: 60px;
  }

  section .content p {
    font-size: 18px;
    line-height: 1.6;
  }
}