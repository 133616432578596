.inspection-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.8);
  margin-left: 4%;
  margin-top: 2%;
}

.ilines-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -3;
  pointer-events: none;
}

.ilines-svg {
  width: 90%; /* Reduce SVG size */
  height: 90%;
}

.iservice-path {
  stroke: #050505;
  stroke-width: 1.8px;
  stroke-linejoin: miter;
  fill: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.iservice-path.animated {
  opacity: 1;
}

.inspection-button {
  width: 100%;
  height: 100%;
  background: transparent;
  border: 2px solid transparent;
  border-color: #061d66;
  color: rgb(3, 3, 3);
  font-family: 'Michroma', sans-serif;
  border-radius: 12px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.3s ease-in-out;
  pointer-events: auto;
  position: relative;
  cursor: pointer;
}

.inspection-button::before {
  content: "";
  position: absolute;
  inset: -2px;
}

.inspection-button:hover {
  color: #ffffff;
  background: #0751b1;
}

.center-logo {
  fill: #fff;
  stroke: #00aaff;
  stroke-width: 2;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.iservice-path.animated {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 2s forwards ease-out;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

.button-animated {
  opacity: 0;
  animation: fadeIn 1s forwards ease-out;
}

/* Media Queries */

/* Mobile (up to 767px) */
/* Mobile (up to 767px) */
@media (max-width: 767px) {
  .inspection-container {
    transform: scale(0.8);
    margin: 0 auto;
    margin-left: -10px;
    margin-top: 1%;
    height: 50vh;
    width: 110%; /* Ensure the container takes full width */
    padding-right: 0; /* Remove any right padding */
  }

  .ilines-container {
    position: absolute;
    width: 100rem; /* Ensure full width */
    height: 100%;
    top: 0;
    left: 20px;
    z-index: -3;
    pointer-events: none;
    padding-right: 0; /* Remove any right padding */
    margin-right: 0; /* Remove any right margin */
  }

  .ilines-svg {
    width: 30rem; /* Ensure the SVG takes full width of its container */
    height: 30rem;
    margin: 0; 
    margin-left: -3.2%;/* Remove any margins */
    padding: 0; /* Remove any padding */
  }

  .iservice-path {
    stroke-width: 2px;
  }

  .inspection-button {
    font-size: 8px;
    max-width: 140px;
    padding: 4px 5px;
  }

 

  .center-logo {
    height: 30%;
    width: 30%;
    x: 360;
    y: 160;
  }
}

/* Laptop (768px - 1199px) */
@media (min-width: 768px) and (max-width: 1199px) {
  .inspection-container {
    transform: scale(0.6);
    margin-left: 2%;
    margin-top: 1.5%;
  }

  .ilines-svg {
    width: 80%;
    height: 80%;
  }

  .iservice-path {
    stroke-width: 1.8px;
  }

  .inspection-button {
    font-size: 10px;
    min-width: 110px;
    padding: 3px 6px;
  }

  .center-icon {
    height: 90px;
    width: 90px;
  }

  .center-logo {
    height: 28px;
    width: 28px;
  }
}

/* Desktop (1200px and above) */
@media (min-width: 1200px) {
  .inspection-container {
    transform: scale(0.8);
    margin-left: 4%;
    margin-top: 2%;
  }

  .ilines-svg {
    width: 90%;
    height: 90%;
  }

  .iservice-path {
    stroke-width: 1.8px;
  }

  .inspection-button {
    font-size: 12px;
    min-width: 140px;
    padding: 4px 8px;
  }

  .center-icon {
    height: 100px;
    width: 100px;
  }

  .center-logo {
    height: 30px;
    width: 30px;
  }
}