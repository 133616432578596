.about-container {
  text-align: center;
  padding: 30px;
  font-family: 'Michroma', sans-serif;
}

.title {
  font-size: 3rem;
  font-weight: bold;
  font-family: 'Michroma', sans-serif;
}

.highlight {
  color: #0f5da7; 
  font-weight: bold; /* Blue highlight */
}

.description {
  font-size: 18px;
  text-align: justify;
  color: #1d1d1d;
  max-width: 800px;
  margin: -30px auto 40px;
  line-height: 1.4;
}

.content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-top: 40px;
  font-family: serif;
}

.image-container {
  flex: 1;
}

.drone-image {
  max-width: 700px;
  height: 500px;
  margin-top: 10px;
}

.text-container {
  flex: 1;
  text-align: left;
  margin-top: 15px;
}

.subtitle1 {
  color: #0438a0;
  font-size: 30px;
  font-weight: bold;
  font-family: 'Michroma', sans-serif;
  margin-bottom: 3px;
  margin-top: -90px;
}

.story-text1 {
  font-size: 15px;
  margin-bottom: 0px;
  margin-top: -2px;
  color: #1d1d1d;
  margin-right: 14px;
  font-family: 'Michroma', sans-serif;
  line-height: 1.4;
  text-align: justify;
}

.subtitle2 {
  color: #0438a0;
  font-size: 30px;
  font-weight: bold;
  font-family: 'Michroma', sans-serif;
  margin-bottom: -10px;
  margin-top: 5px;
}

.story-text2 {
  font-size: 15px;
  margin-bottom: -60px;
  color: #1d1d1d;
  margin-right: 14px;
  font-family: 'Michroma', sans-serif;
  line-height: 1.5;
  text-align: justify;
}

.mission-vision-container {
  display: flex;
  justify-content: center;
  gap: 100px;
  margin-top: 90px;
  flex-wrap: wrap;
}

.mission-content, .vision-content {
  background: rgb(7, 7, 7);
  padding: 10px 40px;
  max-width: 450px;
  border-radius: 8px;
  z-index: -1;
  position: relative;
  flex: 1;
  text-align: left;
}

.mission-title, .vision-title {
  font-size: 36px;
  font-family: 'Michroma', sans-serif;
  font-weight: bold;
  color: #fff;
  text-align: center;
}

.mission-text, .vision-text {
  font-size: 15px;
  color: #fff;
  line-height: 1.8;
  margin-top: -12px;
  font-family: 'Michroma', sans-serif;
  text-align: justify;
}

.why-choose-container {
  text-align: center;
  padding: 10px 20px;
  background-color: #f9f9f9;
  margin-top: 50px;
}

.why-title {
  font-size: 2.3rem;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: 'Michroma', sans-serif;
}

.why-description {
  font-size: 15px;
  color: #252424;
  margin-bottom: 50px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Michroma', sans-serif;
  line-height: 1.4;
}

/* Grid Layout for Features */
.features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates a 2x2 grid */
  gap: 40px;
  max-width: 200rem;
  margin: 0 auto;
}

.features-grid .feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.features-grid .feature-icon {
  width: 100px; /* Adjust size as needed */
  height: auto;
  margin-bottom: -2px;
}

.feature1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
  margin-top: -20px;
}

.feature-icon1 {
  width: 100px; /* Adjust size as needed */
  height: auto;
  margin-bottom: -5px;
}

.feature h3 {
  font-size: 24px;
  max-width: 500px;
  font-weight: bold;
  color: #0f5da7;
  margin-bottom: -2px;
  font-family: 'Michroma', sans-serif;
}

.features h3 {
  font-size: 24px;
  max-width: 500px;
  font-weight: bold;
  color: #0f5da7;
  margin-bottom: 10px;
  margin-top: 17px;
  font-family: 'Michroma', sans-serif;
}

.feature p {
  font-size: 18px;
  color: #2e2e2e;
  max-width: 400px;
  font-family: 'Michroma', sans-serif;
}

.feature1 h3 {
  font-size: 24px;
  font-weight: bold;
  color: #0f5da7;
  margin-bottom: -1px;
  font-family: 'Michroma', sans-serif;
}

.feature1 p {
  font-size: 18px;
  color: #444;
  max-width: 400px;
  font-family: 'Michroma', sans-serif;
}

/* Media Queries */

/* Mobile (up to 767px) */
@media (max-width: 767px) {
  .about-container {
    padding: 20px;
    margin-top: 30px;
  }

  .title {
    font-size: 2rem;
  }

  .description {
    font-size: 14px;
    margin: 10px auto 20px;
    max-width: 100%;
  }

  .content-container {
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }

  .drone-image {
    max-width: 100%;
    height: auto;
  }

  .text-container {
    margin-top: 0;
    
  }

  .subtitle1 {
    font-size: 24px;
    margin-top: 10px;
    text-align: center;
  }

  .subtitle2 {
    font-size: 24px;
    margin-bottom: 5px;
    text-align: center;
  }

  .story-text1, .story-text2 {
    font-size: 14px;
    margin-right: 0;
    margin-bottom: 10px;
    text-align: center;
  }

  .mission-vision-container {
    flex-direction: column;
    gap: 30px;
    margin-top: 40px;
  }

  .mission-content, .vision-content {
    padding: 15px 20px;
    max-width: 100%;
  }

  .mission-title, .vision-title {
    font-size: 28px;
  }

  .mission-text, .vision-text {
    font-size: 14px;
    text-align: center;
  }

  .why-choose-container {
    padding: 20px 10px;
  }

  .why-title {
    font-size: 1.8rem;
  }

  .why-description {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .features-grid {
    grid-template-columns: 1fr;
    gap: 20px;
    max-width: 100%;
  }

  .feature, .feature1 {
    padding: 10px;
    margin-top: 0;
  }

  .feature h3, .feature1 h3 {
    font-size: 20px;
  }

  .feature p, .feature1 p {
    font-size: 14px;
    max-width: 100%;
    text-align: justify;
  }

  .feature-icon, .feature-icon1 {
    width: 80px;
  }
}

/* Laptop (768px - 1199px) */
@media (min-width: 768px) and (max-width: 1199px) {
  .about-container {
    padding: 25px;
  }

  .title {
    font-size: 2.5rem;
  }

  .description {
    font-size: 16px;
  }

  .content-container {
    gap: 30px;
  }

  .drone-image {
    max-width: 500px;
    height: 350px;
  }

  .subtitle1, .subtitle2 {
    font-size: 26px;
  }

  .story-text1, .story-text2 {
    font-size: 14px;
  }

  .mission-vision-container {
    gap: 50px;
    margin-top: 60px;
  }

  .mission-content, .vision-content {
    max-width: 400px;
    padding: 15px 30px;
    text-align: center;
  }

  .mission-title, .vision-title {
    font-size: 32px;
  }

  .mission-text, .vision-text {
    font-size: 14px;
  }

  .why-title {
    font-size: 2rem;
  }

  .why-description {
    font-size: 14px;
  }

  .features-grid {
    gap: 30px;
    max-width: 800px;
  }

  .feature h3, .feature1 h3 {
    font-size: 22px;
  }

  .feature p, .feature1 p {
    font-size: 16px;
  }

  .feature-icon, .feature-icon1 {
    width: 90px;
  }
}

/* Desktop (1200px and above) */
@media (min-width: 1200px) {
  .about-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px;
  }

  .title {
    font-size: 3rem;
  }

  .description {
    font-size: 18px;
  }

  .content-container {
    gap: 40px;
    margin-top: 40px;
  }

  .drone-image {
    max-width: 700px;
    height: 500px;
  }

  .subtitle1, .subtitle2 {
    font-size: 30px;
  }

  .story-text1, .story-text2 {
    font-size: 15px;
  }

  .mission-vision-container {
    gap: 100px;
    margin-top: 90px;
  }

  .mission-content, .vision-content {
    max-width: 450px;
  }

  .mission-title, .vision-title {
    font-size: 36px;
  }

  .mission-text, .vision-text {
    font-size: 15px;
  }

  .why-title {
    font-size: 2.3rem;
  }

  .why-description {
    font-size: 15px;
  }

  .features-grid {
    max-width: 1000px;
    gap: 40px;
  }

  .feature h3, .feature1 h3 {
    font-size: 24px;
  }

  .feature p, .feature1 p {
    font-size: 18px;
  }

  .feature-icon, .feature-icon1 {
    width: 100px;
  }
}