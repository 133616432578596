html, #root {
  height: 100vh;
  margin-top: 19px;
  display: flex;
  flex-direction: column;
  font-family: 'Michroma', sans-serif;
}

.wrapper {
  display: flex;
  flex-direction: column; /* Ensures the wrapper takes full viewport height */
}

.main-content {
  flex: 1; /* Pushes footer down */
  padding-bottom: 20px;
}

.footer {
  width: 100%;
  background-color: #000000;
  color: #ffffff;
  text-align: left;
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: serif;
}

/* Main footer layout */
.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 9%;
  margin-left: 5%;
  flex-wrap: wrap;
}

.footer-left {
  display: flex;
  align-items: center;
  gap: 30px;
}

.footer-logo {
  width: 100px;
}

.footer-left img {
  margin-top: 29px;
  margin-left: -60px;
}

.footer-title {
  font-size: 2rem;
  font-weight: bold;
  font-family: 'Michroma', sans-serif;
  margin-left: -20px;
  margin-top: 40px;
}

.company-tagline {
  font-size: 15px;
  color: #417eda;
  margin-top: -20px;
  margin-left: -20px;
  font-family: 'Michroma', sans-serif;
}

.footer-container .footer-center {
  margin-left: 0;
}


.footer-container .footer-right {
  margin-right: -7rem;
  margin-top: -2px;
  width: 37%;
  text-align: left;
  font-weight: normal;
}

.footer-center{
  margin-right: 70px;
}

.footer-social {
  padding: 2px;
  margin-right: 1rem;
  width: 20%;
  text-align: left;
  margin-top: -0.5%;
  margin-left: 30px;
}

.footer-social h3 {
  font-size: 1.4rem;
  margin-top: 19px;
  margin-bottom: 7%;
  font-family: 'Michroma', sans-serif;
}

.footer-center h3,
.footer-right h3 {
  font-size: 1.4rem;
  font-weight: bold;
  font-family: 'Michroma', sans-serif;
  margin-bottom: 5px;
  color: #417eda;
}

p{
  font-size: 14px;
}

.footer-center p,
.footer-right p {
  margin: 0;
  line-height: 1.5;
  font-family: 'Michroma', sans-serif;
}

/* Footer Links and Subscription Wrapper */
.footer-links-subscribe {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 10px 15px;
  margin-top: -2px;
  flex-wrap: wrap;
}

/* Ensure Subscribe and Stay In Touch are aligned */
.footer-subscribe,
.footer-social {
  flex: 1;
  max-width: 40%;
  margin-bottom: 4%;
}

.subscribe-input input {
  width: 100%;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.footer-social h3 {
  font-size: 1.4rem;
  color: #4378eb;
}

/* New Quick Links Wrapper */
.footer-links-wrapper {
  display: flex;
  gap: 50px;
  flex: 2;
  margin-left: 50px;

}

/* Individual Quick Links */
.footer-nav ul {
  list-style: none;
  padding: 0;
  margin-top: -3%;
}

.footer-nav ul h3 {
  margin-bottom: 10px;
  font-size: 1.4rem;
  color: #466ae2;
  font-family: 'Michroma', sans-serif;
}

.footer-nav ul li {
  margin-bottom: 5px;
  
}

.footer-nav ul li a {
  text-decoration: none;
  font-size: 14px;
  font-family: 'Michroma', sans-serif;
  color: #ffffff;
  transition: 0.3s ease-in-out;
}

.footer-nav ul li a:hover {
  color: #5ed3f0;
}

/* Subscription Section */
.footer-subscribe {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 1px;
  margin-left: -3%;
}

.subscribe-text h2 {
  font-size: 1.4rem;
  font-family: 'Michroma', sans-serif;
  font-weight: bold;
  margin-bottom: 5px;
  color: #4378eb;
}

.subscribe-text p {
  font-size: 12px;
  color: white;
  font-family: 'Michroma', sans-serif;
  margin-top: -2px;
}

.subscribe-input {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.subscribe-input input {
  padding: 8px;
  font-size: 10px;
  font-family: 'Michroma', sans-serif;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-width: 300px;
  margin-bottom: -5px;
}

.subscribe-input .butn button {
  padding: 10px 15px;
  margin-left: 32%;
  color: rgb(5, 5, 5);
  border: none;
  cursor: pointer;
  border-radius: 15px;
  transition: 0.3s ease-in-out;
  font-size: 14px;
  font-family: 'Michroma',sans-serif;
  margin-top: -1px;
  margin-bottom: 10px;
}

.subscribe-input .butn button:hover {
  background-color: #081861;
  color: #ffffff;
}

/* Social Media Icons */
.social-icons {
  display: flex;
  gap: 20px;
  margin-top: -12px;
  width: 290px;
}

.social-icon {
  font-size: 24px;
  color: #ffffff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: 0.3s ease-in-out;
  background: #222;
  margin-top: 2px;
}

.social-icon:hover {
  background: #052c80;
}

/* Help Icon */
.help-icon {
  margin-top: 16px;
}

.help-link {
  font-size: 14px;
  color: white;
  text-decoration: none;
  font-family: 'Michroma', sans-serif;
  display: flex;
  align-items: center;
  gap: 5px;
}

.help-link i {
  font-size: 20px;
}

.help-link:hover {
  color: #2e47d4;
}

.contacts-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  text-align: center;
  cursor: pointer;
  z-index: 1000;
}

.contacts-icon img {
  width: 50px;
  height: 50px;
  transition: transform 0.3s ease-in-out;
}

/* Wobble + Pulse Animation for Phone Icon */
.contacts-icon:hover img {
  animation: wobble 0.6s ease-in-out, pulse 1s infinite;
}

/* Phone Number Tooltip Animation */
.contacts-number {
  display: none;
  font-size: 12px;
  background-color: #ffffff;
  border: 2px rgb(4, 41, 88) solid;
  padding: 7px 10px;
  min-width: 70px;
  text-align: left;
  border-radius: 5px;
  position: absolute;
  bottom: 60px;
  right: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(20px) scale(0.8);
  transition: transform 0.4s ease-out, opacity 0.3s ease-out, box-shadow 0.3s ease-in-out;
}

.contacts-icon:hover .contacts-number {
  display: block;
  opacity: 1;
  transform: translateY(0) scale(1);
  animation: bounceIn 0.6s ease-out, glow 1.5s infinite alternate;
}

/* Wobble Effect */
@keyframes wobble {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-10deg); }
  50% { transform: rotate(10deg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

/* Pulse Effect */
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

/* Bounce-in Effect for Phone Number */
@keyframes bounceIn {
  0% { transform: translateY(20px) scale(0.8); opacity: 0; }
  50% { transform: translateY(-5px) scale(1.05); opacity: 0.5; }
  100% { transform: translateY(0) scale(1); opacity: 1; }
}

/* Glow Effect */
@keyframes glow {
  0% { box-shadow: 0 0 5px rgba(255, 255, 255, 0.3); }
  100% { box-shadow: 0 0 15px rgba(255, 255, 255, 0.8); }
}

/* Media Queries */

/* Mobile (up to 767px) */
/* Mobile (up to 767px) */
@media (max-width: 767px) {
  .footer-container {
    margin-right: 10%;
    flex-direction: column;
    padding: 0 15px;
    align-items: center;
    text-align: center;
  }

  .footer-left {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
  }

  .footer-left img {
    margin: 15px 10 0 0;
    width: 80px; /* Reduced logo size for mobile */
  }

  .footer-title {
    font-size: 1.2rem; /* Reduced font size for mobile readability */
    margin: 10px 0 0 0;
    margin-left: -16%;
    margin-top: -2%;
  }

  .company-tagline {
    font-size: 12px;
    margin-top: 5px; /* Adjusted spacing */
  }

  .footer-container .footer-center,
  .footer-right {
    width: 100%;
    text-align: center;
  }

  .footer-center {
    margin-left: 0;
    
  }

  .footer-container .footer-right {
    width: 100%;
    text-align: center;
    margin-left: -10%;
    margin-right: 10%;
  }

  .footer-right h3 {
    color: #2286eb;
    margin-bottom: 10px;
  }

  .footer-right p {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .footer-links-subscribe {
    flex-direction: column;
    padding: 15px;
    align-items: center; /* Center-align the content */
  }

  .footer-links-wrapper {
    flex-direction: row;
    gap: 20px; /* Increased gap for better spacing */
    width: 100%;
    margin-left: 5%; /* Removed margin to center content */
    text-align: center; /* Center-align text */
  }

  .footer-nav {
    text-align: center;
    display: flex;
    flex: 1;
  }

  .footer-nav ul {
    padding: 0;
    margin: 0; /* Removed negative margin for cleaner layout */
  }

  .footer-nav ul h3 {
    font-size: 1.2rem; /* Reduced font size for mobile */
    color: #466ae2;
    margin-bottom: 10px;
  }

  .footer-nav ul li {
    margin-bottom: 10px; /* Increased spacing between list items */
  }

  .footer-nav ul li a {
    font-size: 14px; /* Kept readable font size */
    color: #ffffff;
  }

  .footer-subscribe {
    max-width: 100%;
    margin: 15px 0;
    margin-right: 4%;
    text-align: center; /* Center-align subscription text */
  }

  .subscribe-text h2 {
    font-size: 1.2rem; /* Reduced font size for mobile */
    margin-bottom: 10px;
  }

  .subscribe-text p {
    font-size: 12px; /* Reduced font size for mobile readability */
    margin-top: 5px;
  }

  .subscribe-input {
    width: 100%;
    max-width: 100%; /* Ensure input takes full width on mobile */
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center; /* Center-align input */
  }

  .subscribe-input input {
    width: 100%; /* Full width for mobile input */
    max-width: 280px; /* Limited max width for usability */
    padding: 10px; /* Increased padding for better touch targets */
    font-size: 14px; /* Increased font size for readability */
    margin-bottom: 10px; /* Added spacing */
  }

  .subscribe-input .butn button {
    width: 100%; /* Full width for mobile button */
    max-width: 200px; /* Limited max width for usability */
    padding: 12px; /* Increased padding for better touch targets */
    margin-left: 0; /* Removed left margin to center */
    font-size: 14px;
  }

  .footer-social {
    max-width: 100%;
    margin-right: 0;
    margin-left: -10%;
    text-align: center;
    width: 100%;
    margin-top: 15px; /* Added spacing */
  }

  .footer-social h3 {
    font-size: 1.2rem; /* Reduced font size for mobile */
    margin-bottom: 10px;
  }

  .social-icons {
    justify-content: center;
    gap: 15px; /* Reduced gap for mobile */
    width: 100%;
    margin-top: 10px; /* Added spacing */
  }

  .social-icon {
    font-size: 20px; /* Reduced icon size for mobile */
    width: 35px;
    height: 35px;
  }

  .help-icon {
    display: flex;
    justify-content: center;
    margin-top: 15px; /* Added spacing */
  }

  .contacts-icon img {
    width: 40px;
    height: 40px;
  }

  .contacts-number {
    font-size: 12px;
    margin-right: 3%;
    padding: 5px 8px;
    bottom: 70px; /* Adjusted position for mobile */
  }
}

/* Laptop (768px - 1199px) */
@media (min-width: 768px) and (max-width: 1199px) {
  .footer-container {
    padding: 0 5%;
  }

  .footer-left {
    gap: 15px;
  }

  .footer-logo {
    width: 80px;
  }

  .footer-title {
    font-size: 1.8rem;
  }

  .company-tagline {
    font-size: 13px;
  }

  .footer-center,
  .footer-right{
    margin-left: 0;
    margin-right: 0;
    width: 25%;
  }

  .footer-center h3,
  .footer-right h3,
  .footer-social h3,
  .footer-nav ul h3,
  .subscribe-text h2 {
    font-size: 1.3rem;
  }

  .footer-nav ul li a,
  .footer-center p,
  .footer-right p {
    font-size: 11px;
  }

  .footer-subscribe{
    margin-right: 3%;
  }

  .footer-links-subscribe {
    padding: 10px 5%;
  }



  .subscribe-input input {
    width: 250px;
  }

  .subscribe-input .butn button {
    margin-left: 20%;
  }

  .social-icons {
    gap: 15px;
    width: 250px;
  }
}

/* Desktop (1200px and above) */
@media (min-width: 1200px) {
  .footer-container {
    padding: 0 7%;
  }

  .footer-subscribe{
    margin-left: 2%;
    margin-right: -2%;
    margin-left: -120px;
  }

  .footer-links-subscribe {
    padding: 10px 12% 0;
  }

  .footer-logo {
    width: 100px;
  }

  .footer-title {
    font-size: 2rem;
  }

  .footer-center h3,
  .footer-right h3,
  .footer-social h3,
  .footer-nav ul h3,
  .subscribe-text h2 {
    font-size: 1.4rem;
  }

  .footer-center{
    margin-left: -15%;
  }

  .footer-nav ul li a,
  .footer-center p,
  .footer-right p {
    font-size: 12px;
  }

  
  .footer-links-subscribe{
    margin-left: 1%;
  }

  .subscribe-input input {
    width: 350px;
  }

  .footer-social{
    margin-left: 8%;
  }

  .social-icons {
    width: 290px;
    margin-left: 4%;
  }
}