.agriculture-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: auto;
  padding: 10px;
  margin-top: 30px;
}

section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px; /* decreased from 360px */
  margin: 15px 0; /* reduced spacing between sections */
  padding: 10px;
  gap: 5px;
  line-height: 1.6;
}
 section img {
  padding: 20px;
  height: 80%;
  width: 80%;
  border-radius: 10px;
}

img{
  border-radius: 10px;
}

section .content {
  width: 70%;
  height: 380px;
  padding: 20px;
  margin-top: 5%;
}

section .content h2 {
  margin-top: 15%;
  font-size: 2rem;
  color: #014c97;
  margin-bottom: 50px;
  font-family: 'Michroma', sans-serif;
}

section .content p {
  font-size: 16px;
  color: #000000;
  text-align: justify;
  font-family: 'Michroma', sans-serif; /* Fixed typo: 'Michroa' to 'Michroma' */
  line-height: 1.3;
}

.seed-agriculture {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1px; /* Replaces margin-left and margin-right */
}



/* Rowwise */
.spraying-agriculture img {
  margin-left: 6%;
}

.crop-agriculture img {
  margin-left: 6%;
}

.spraying-agriculture .content {
  margin-right: 6%;
  width: 40%;
}

.crop-agriculture .content {
  margin-right: 6%;
}

/* Row-reverse */
.seed-agriculture img {
  margin-right: 6%;
  width: 35%;
  padding: 20px;
  margin: 0;

}

.farm-agriculture img {
  margin-right: 6%;
}

.seed-agriculture .content {
   width: 55%; /* or whatever value fits nicely */
  height: auto;
  padding: 20px;
  margin: 0;

}

.farm-agriculture .content {
  margin-left: 6%;
}

/* Media Queries */

/* Mobile (up to 767px) */
@media (max-width: 767px) {
  .agriculture-details-container {
    padding: 5px;
    margin-top: 40px;
    margin-left: -1.5%;
  }

  section {
    flex-direction: column;
    height: auto;
    margin: 20px 0;
    padding: 10px;
    gap: 5%;
  }

  section img {
    width: 80%;
    padding: 10px;
    margin: 0;
  }

  section .content {
    width: 90%;
    height: auto;
    padding: 10px;
  }

  section .content h2 {
    font-size: 1.5rem;
    margin-top: 10px;
  }

  section .content p {
    font-size: 14px;
    line-height: 1.4;
    text-align: justify;
  }

  /* Reset margins for mobile */
  .spraying-agriculture img,
  .seed-agriculture img,
  .crop-agriculture img,
  .farm-agriculture img {
    margin-left: 0;
    margin-right: 0;
  }

  .seed-agriculture .content,
  .farm-agriculture .content{
    margin-top: -17%;
  }

  .spraying-agriculture .content,
  .seed-agriculture .content,
  .crop-agriculture .content,
  .farm-agriculture .content {
    margin-left: 0;
    margin-right: 0;
  }
}

/* Laptop (768px - 1199px) */
@media (min-width: 768px) and (max-width: 1199px) {
  .agriculture-details-container {
    padding: 8px;
    margin-top: 20px;
  }

  section {
    height: 400px;
    margin: 30px 0;
    gap: 8%;
  }

  section img {
    width: 40%;
    padding: 15px;
  }

  section .content {
    width: 60%;
    height: 450px;
    padding: 15px;
  }

  section .content h2 {
    font-size: 1.8rem;
    margin-top: 12%;
  }

  section .content p {
    font-size: 15px;
    line-height: 1.3;
  }

  /* Adjust margins */
  .spraying-agriculture img,
  .seed-agriculture img {
    margin-left: 4%;
  }

  .seed-agriculture img,
  .farm-agriculture img {
    margin-right: 4%;
  }

  .spraying-agriculture .content,
  .seed-agriculture .content {
    margin-right: 4%;
  }

  .seed-agriculture .content,
  .farm-agriculture .content {
    margin-left: 4%;
  }
}

/* Desktop (1200px and above) */
@media (min-width: 1200px) {
  .agriculture-details-container {
    padding: 10px;
    margin-top: 30px;
  }

  section {
    height: 440px;
    margin: 40px 0;
    gap: 10%;
  }

  section img {
    width: 35%;
    padding: 20px;
  }

  section .content {
    width: 70%;
    height: 500px;
    padding: 20px;
  }

  section .content h2 {
    font-size: 2rem;
    margin-top: 15%;
  }

  section .content p {
    font-size: 16px;
    line-height: 1.3;
  }

  /* Keep original margins */
  .spraying-agriculture img,
  .seed-agriculture img {
    margin-left: 6%;
  }

  .seed-agriculture img,
  .farm-agriculture img {
    margin-right: 6%;
  }

  .spraying-agriculture .content,
  .seed-agriculture .content {
    margin-right: 6%;
  }

  .seed-agriculture .content,
  .farm-agriculture .content {
    margin-left: 6%;
  }
}