.survey-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: auto;
  padding: 10px;
  margin-top: 30px;
}

section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px; /* decreased from 360px */
  margin: 15px 0; /* reduced spacing between sections */
  padding: 10px;
  gap: 5px;
  line-height: 1.6;
}
 section img {
  height: 80%;
  width: 80%;
  border-radius: 10px;
  padding: 20px;
}
img{
  border-radius: 10px;
}

section .content {
  width: 70%;
  height: 380px;
  padding: 20px;
  margin-top: 5%;
}

section .content h2 {
  margin-top: 15%;
  font-size: 1rem;
  color: #014c97;
  margin-bottom: 50px;
  font-family: 'Michroma', sans-serif;
}

section .content p {
  font-size: 16px;
  color: #000000;
  text-decoration: none;
  text-align: justify;
  font-family: 'Michroma', sans-serif;
  line-height: 1.5;
}

/* Rowwise */
.forest-mapping img {
  margin-left: 6%;
}

.land-mapping img {
  margin-left: 6%;
}

.disaster-mapping img {
  margin-left: 6%;
}

.smart-mapping img {
  margin-left: 6%;
}

.forest-mapping .content {
  margin-right: 6%;
}

.land-mapping .content {
  margin-right: 6%;
}

.disaster-mapping .content {
  margin-right: 6%;
}

.smart-mapping .content {
  margin-right: 6%;
}

/* Row-reverse */
.road-mapping img {
  margin-right: 6%;
}

.river-mapping img {
  margin-right: 6%;
}

.mining-mapping img {
  margin-right: 6%;
}

.lidar-mapping img {
  margin-right: 6%;
}

.road-mapping .content {
  margin-left: 6%;
  /* width: 35%; */
}

.river-mapping .content {
  margin-left: 6%;
}

.mining-mapping .content {
  margin-left: 6%;
}

.lidar-mapping .content {
  margin-left: 7%;
}

/* New Media Queries */
@media only screen and (max-width: 767px) {
  .survey-details-container {
    padding: 5px;
    margin-top: 45px;
    margin-right: 5%;
    margin-left: -1.5%;
  }

  section {
    flex-direction: column;
    height: auto;
    margin: 10px 10px;
    padding: 10px;
    gap: 5%;
  }

  section img {
    width: 100%; /* Adjusted for full-width scaling */
    padding: 10px;
    margin-right: 3%;
    margin: 0 auto; /* Center align */
    display: block;
  }

  section .content {
    width: 100%;
    height: auto;
    padding: 15px;
  }

  section .content h2 {
    font-size: 1.4rem; /* Increased for better readability */
    margin-top: 5%;
    margin-bottom: 15px;
  }

  section .content p {
    font-size: 14px; /* Slightly larger for readability */
    line-height: 1.5;
    text-align: justify;
  }

  /* Reset margins for mobile */
  .forest-mapping img,
  .land-mapping img,
  .disaster-mapping img,
  .smart-mapping img,
  .road-mapping img,
  .river-mapping img,
  .mining-mapping img,
  .lidar-mapping img {
    margin: 0 auto; /* Center images */
    display: block;
  }

  .forest-mapping .content,
  .land-mapping .content,
  .disaster-mapping .content,
  .smart-mapping .content,
  .road-mapping .content,
  .river-mapping .content,
  .mining-mapping .content,
  .lidar-mapping .content {
    margin: 0;
    padding: 10px;
  }
  .road-mapping .content,
  .river-mapping .content,
  .mining-mapping .content,
  .lidar-mapping .content{
    margin-top: -15%;
  }
}


@media only screen and (min-width: 601px) and (max-width: 900px) {
  .survey-details-container {
    padding: 10px;
    margin-top: 20px;
  }

  section {
    height: auto;
    margin: 25px 10px;
    gap: 7%;
  }

  section img {
    width: 45%;
    padding: 15px;
  }

  section .content {
    width: 50%;
    height: auto;
    padding: 15px;
  }

  section .content h2 {
    font-size: 1.4rem;
    margin-top: 10%;
    margin-bottom: 30px;
  }

  section .content p {
    font-size: 14px;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1200px) {
  .survey-details-container {
    padding: 15px;
  }

  section {
    height: 400px;
    margin: 30px 15px;
    gap: 8%;
  }

  section img {
    width: 40%;
    padding: 15px;
  }

  section .content {
    width: 55%;
    padding: 15px;
  }

  section .content h2 {
    font-size: 1.6rem;
    margin-top: 12%;
    margin-bottom: 40px;
  }

  section .content p {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1600px) {
  .survey-details-container {
    padding: 20px;
  }

  section {
    height: 440px;
    margin: 40px 20px;
    gap: 10%;
  }

  section img {
    width: 35%;
    padding: 20px;
  }

  section .content {
    width: 60%;
    padding: 20px;
  }

  section .content h2 {
    font-size: 1.8rem;
    margin-top: 15%;
  }

  section .content p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1601px) {
  .survey-details-container {
    padding: 30px;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }

  section {
    height: 500px;
    margin: 50px 30px;
    gap: 12%;
  }

  section img {
    width: 30%;
    padding: 25px;
  }

  section .content {
    width: 65%;
    padding: 25px;
  }

  section .content h2 {
    font-size: 2rem;
    margin-top: 15%;
    margin-bottom: 60px;
  }

  section .content p {
    font-size: 18px;
    line-height: 1.6;
  }
}