.inspection-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: auto;
  padding: 10px;
  margin-top: 30px;
  
}
section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px; /* decreased from 360px */
  margin: 15px 0; /* reduced spacing between sections */
  padding: 10px;
  gap: 5px;
  line-height: 1.6;
}


section img {
  border-radius: 10px;
  padding: 20px;
  height: 80%;
  width: 80%;
}

section .content {
  width: 70%;
  height: 380px;
  padding: 20px;
  margin-top: 5%;
}

section .content h2 {
  margin-top: 15%;
  font-size: 2.5rem;
  color: #014c97;
  margin-bottom: 10px;
  font-family: 'Michroma',sans-serif;
}

section .content p {
  font-size: 16px;
  color: #000000;
  text-align: justify;
  font-family: 'Michroma',sans-serif;
  line-height: 1.3;
}


@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  50% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-20px);
  }
}

/* .icon-animation {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  animation: slideInRight 3s ease-in-out;
  pointer-events: none;
} */


.wind-turbine-inspection img{
  
  margin-left: 6%;
}

.bridge-inspection img{
 
  margin-left: 6%;
}

.cooling-tower-inspection img{
  
  margin-left: 6%;
}

.pipeline-inspection img{
  
  margin-left: 6%;
}

.chimney-inspection img{
  
  margin-left: 6%;
}

.wind-turbine-inspection .content{
  
  margin-right: 6%;
  margin-top: 7%;
}

.bridge-inspection .content{
  
  margin-right: 6%;
}

.cooling-tower-inspection .content{
  
  margin-right: 6%;
}

.pipeline-inspection .content{
  
  margin-right: 6%;
}

.chimney-inspection .content{
 
  margin-right: 6%;
}

.solar-panel-inspection img{
  
  margin-right: 6%;
}

.transmission-line-inspection img{

  margin-right: 6%;
}

.boiler-inspection img{
  
  margin-right: 6%;
  height: 70%;
  width: 70%;
}

.railway-inspection img{
  
  margin-right: 6%;
}

.celltower-inspection img{
  
  margin-right: 6%;
}

.solar-panel-inspection .content{
  margin-left: 6%;
}

.transmission-line-inspection .content{
  margin-left: 6%;
}

.boiler-inspection .content{
  margin-left: 6%;
}

.railway-inspection .content{
  margin-left: 6%;
}

.celltower-inspection .content{
  margin-left: 6%;
}

@media (max-width: 767px) {
.inspection-details-container {
  padding: 5px;
  margin-left: -7%;
}

section {
  flex-direction: column;
  height: auto;
  margin: 10px 10px;
  gap: 5%;
}

section img {
  width: 80%;
  padding: 10px;
}

section .content {
  width: 100%;
  padding: 10px;
  text-align: center;
  margin-left: 30px;
}

section .content h2 {
  font-size: 1.8rem;
  margin-top: 5%;
}

.solar-panel-inspection img,
.transmission-line-inspection img,
.boiler-inspection img,
.railway-inspection img,
.celltower-inspection img{
  margin-left: 10%;
}
.solar-panel-inspection .content,
.transmission-line-inspection .content,
.boiler-inspection .content,
.railway-inspection .content,
.celltower-inspection .content{
  margin-top: -15%;
}

section .content p {
  font-size: 14px;
  text-align: justify;
}
}

@media (max-width: 1366px) {
.inspection-details-container {
  padding: 10px;
}

section {
  height: auto;
  margin: 30px 20px;
  gap: 7%;
}

section img {
  width: 30%;
  padding: 15px;
}

section .content {
  width: 65%;
  padding: 15px;
}

section .content h2 {
  font-size: 2rem;
  margin-top: 10%;
}

section .content p {
  font-size: 16px;
}
}

@media (max-width: 1920px) {
.inspection-details-container {
  padding: 20px;
}

section {
  height: 460px;
  margin: 40px;
  gap: 10%;
}

section img {
  width: 20%;
  padding: 20px;
}

section .content {
  width: 70%;
  padding: 20px;
}

section .content h2 {
  font-size: 2.5rem;
  margin-top: 15%;
}

section .content p {
  font-size: 18px;
}
}
